import { useState, useEffect } from "react";
import styled from "styled-components";
import CompanyAccount from "./companyAccount";
import CompanyGeneralInfo from "./companyGeneralInfo";
import CompanyContacts from "../companies/companyContacts";
import CompanyCoachings from "../companies/companyCoachings";
import CompanyPricesCoachings from "../companies/CompanyPricesCoachings";
import axios from "axios";

let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const Wrapper = styled.div`
  width: 100vw;
  min-height: ${Height};
  display: flex;
  justify-content: center;
`;
const ContentWrapper = styled.div`
  background-color: white;
  width: 90%;
  padding: 30px;
  border-radius: 20px;
  margin: 30px 0;
`;


const SectionWrapper = styled.div`
  background-color: #fbf6f3;
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 20px;
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 5px;
`;


const CompanyHome = () => {
  const [company, setCompany] = useState({});
  const [user, setUser] = useState({});
  const [loader, setLoader] = useState(true);
  const TOKEN = localStorage.getItem("TOKEN");

  const updateUser = (newUser) => {
    setUser(newUser);
  };

  useEffect(() => {
    const getCompany = async () => {
      setLoader(true);
      const TOKEN = localStorage.getItem("TOKEN");
      const config = {
          headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${TOKEN}`,
          },
      };
      try {
         const response = await axios.post(
          `${process.env.REACT_APP_GET_COMPANY}`, { name: user.username },
          config
      );
  
      setCompany(response.data)
      setLoader(false);
      } catch(err) {
        console.log(err)
      }
    }

    getCompany()
  }, [company._id, TOKEN])

  return (
    <Wrapper>
      <ContentWrapper>
        <SectionWrapper>
          <CompanyAccount company={company} updateUser={updateUser}/>
        </SectionWrapper>
        <SectionWrapper>
          <CompanyGeneralInfo company={company} updateUser={updateUser}/>
        </SectionWrapper>
        <SectionWrapper>
          {!loader && (
            <CompanyContacts contacts={company?.contacts} company_id={company?._id} />
          )}
        </SectionWrapper>
        <SectionWrapper>
          {!loader && (
            <CompanyCoachings coachings={company?.coachings} company_id={company?._id} user={user} />
            )}
        </SectionWrapper>
        <SectionWrapper>
            <CompanyPricesCoachings  />
        </SectionWrapper>
      </ContentWrapper>
    </Wrapper>
  );
};

export default CompanyHome;
