import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { ButtonPrimary, ButtonSecondary } from "../buttonStyles/style";
import { H6, LoadingIcon, SmallBoldText } from "../textStyles/style";
import axios from "axios";
import { useState } from "react";
import styled from "styled-components";
import PhoneInput from "react-phone-number-input";
import { genderOptions } from "../../data/SelectOptions";
import { Input } from "../Inputs";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
`;

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;

export default function UpdateCoachingPrices({
  isOpen,
  setIsOpen,
  price_id,
  setSelectedCoachingPrice,
}) {
  const history = useHistory();
  const [price, setPrice] = useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [submitLoader, setSubmitLoader] = React.useState(false);
  const handleClose = () => {
    if (!submitLoader) {
      setIsOpen(false);
      setErrorMessage("");
      setSelectedCoachingPrice(null);
    }
  };

  const submitHandler = () => {
    if (!submitLoader) {
      setErrorMessage("");
      setSubmitLoader(true);

      if (price === "") {
        setErrorMessage("Please fill coaching Price");
        setSubmitLoader(false);
      } else {
        const TOKEN = localStorage.getItem("TOKEN");
        axios
          .put(`${process.env.REACT_APP_COACHING_PRICES}`, {
            access_token: TOKEN,
            price_id,
            price
          })
          .then(() => {
            setSubmitLoader(false);
            history.go(0);
          })
          .catch((err) => {
            setSubmitLoader(false);
            console.log(err);
          });
      }
    }
  };

  useEffect(() => {
    setPrice(price);
  }, [price._id]);


  return (
    <Dialog fullWidth maxWidth="md" open={isOpen} onClose={handleClose}>
      <TitleContainer>
        <H6>Update Coaching Price</H6>
      </TitleContainer>

      <DialogContent>
        {/** form */}
        <Row>
          <Input
            values={genderOptions}
            placeholder="price"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
        </Row>
        {/** error message */}
        <SmallBoldText
          style={{ textAlign: "center", fontSize: 12, color: "#c22222" }}
        >
          {errorMessage ? errorMessage : " - "}
        </SmallBoldText>
      </DialogContent>

      <DialogActions style={{ marginTop: -10, marginBottom: 15 }}>
        <ButtonPrimary onClick={submitHandler}>
          {submitLoader ? <LoadingIcon color="#ffffff" /> : "Update"}
        </ButtonPrimary>

        <ButtonSecondary onClick={handleClose}>Close</ButtonSecondary>
      </DialogActions>
    </Dialog>
  );
}
