import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import moment from 'moment';
import html2pdf from "html2pdf.js";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Typography,
} from "@mui/material";
import { lislColors } from '../../../styles/colors';
import { ButtonPrimary } from '../../../components/buttonStyles/style';
import { Print } from '@material-ui/icons';
import { Logo } from '../../../components/header/style';
import logo from "../../../images/main/green-logo.svg";
import axios from "axios"

let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const Wrapper = styled.div`
  width: 100vw;
  min-height: ${Height};
  display: flex;
  justify-content: center;
`;
const ContentWrapper = styled.div`
  background-color: white;
  width: 753px;
  padding: 20px;
  border-radius: 20px;
`;

const Invoice = () => {
  const [coaching, setCoaching] = useState({})
  const [loader, setLoader] = useState(true)
  const TOKEN = localStorage.getItem("TOKEN");
  const coaching_id = window.location.pathname.split('/')[2]
  const tableHeader = [
    "pack",
    "coaching_code",
    "coachee email",
    "order_date",
    "total"
  ];
  
  useEffect(() => {
    const getCompany = async () => {
      setLoader(true);
      const TOKEN = localStorage.getItem("TOKEN");
      const config = {
          headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${TOKEN}`,
          },
      };
      try {
         const response = await axios.post(
          `http://localhost:4400/dashboard/coachings`, { coaching_id },
          config
      );

      setCoaching(response.data.coaching)
  
      setLoader(false);
      } catch(err) {
        console.log(err)
      }
    }

    getCompany()
  }, [coaching._id, TOKEN])

    const now = new Date();
    const formattedDate = moment(now).format("DD-MM-YYYY");

    const printHandler = () => {
        const element = document.getElementById("to-print");
        const opt = {
            filename: `Example Invoice-${formattedDate}.pdf`,
            margin: 5,

            jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        };

        html2pdf().set(opt).from(element).save();
    };

    return (
        <Wrapper>
          <div
            style={{
              position: "absolute",
              right: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <ButtonPrimary onClick={printHandler}>
              <Print />
            </ButtonPrimary>
          </div>
          <ContentWrapper>
        <div>
          {/** general info */}
          <div id="to-print">
            <div>
              <div style={{ height: "287mm", position: 'relative', marginLeft: 15, marginRight: 15 }}>
                <div
                  style={{
                    position: "absolute",
                    bottom: 10,
                    right: 0,
                    borderBottom: "1px #484848 dashed",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Typography color="#484848" fontSize={10} fontWeight={'bold'}>
                      Conditions de paiement 30 jours date de facturation
                    </Typography>
                    <Typography color="#484848" fontSize={10}>
                      En l'absence de paiement, taux d’intérêt légal comme base de calcul de pénalité de retard à compter du 30/01/2023 : 2,28 % 
                      En cas de retard de paiement, une indemnité forfaitaire de 40 € pour frais de recouvrement sera appliquée.
                    </Typography>

                    <Typography color="#484848" fontSize={10} style={{ marginTop: '8px'}}>
                    MASSA TRAILS SAS Société par Actions Simplifiée au capital de 20 000 € enregistrée au R.C.S de PARIS sous le n° 90404456700017 - TVA Intracommunautaire : FR36 904044567 -  APE : 6312Z - Siège social : 17 rue des Batignolles - 75017 PARIS  contact@lislup.com +33 6 51 56 30 68 | +33 6 99 20 80 20                                                
                    </Typography>

                    <Typography color="#484848" fontSize={10} style={{ marginTop: '8px'}}>
                    Vous pouvez effectuer votre règlement par virement sur le compte suivant : Titulaire du compte : MASSA TRAILS                                              
                    </Typography>
                  </div>
                </div>
                <Logo
                  style={{
                    width: 400,
                    height: 160,
                    alignSelf: "center",
                    position: "absolute",
                    top: "120mm",
                    opacity: 0.25,
                    left: "45mm",
                  }}
                  src={logo}
                  alt="image"
                />
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <Logo
                      style={{
                        width: 200,
                        height: 80,
                        marginLeft: -20,
                        marginBottom: 5,
                      }}
                      src={logo}
                      alt="image"
                    />
                  </div>
                  <div>


                    <Typography color="#484848" textAlign="right" fontSize={13}>
                      {formattedDate}
                    </Typography>
                    <div style={{ marginTop: 10 }}>
                      <Typography textAlign="right" color={lislColors.black} fontSize={12}>
                        MASSA TRAILS SAS
                      </Typography>
                      <Typography textAlign="right" color="#444" fontSize={10}>
                        47 rue des Batignolles – 75017 PARIS
                      </Typography>
                    </div>
                  </div>
                </div>
                <TableContainer style={{ paddingLeft: 10, margintop: 15 }}>
                  <Table>
                    <TableHead>
                      {tableHeader.map((el) => {
                        return (
                          <TableCell
                            width="20%"
                            align="center"
                            style={{
                              fontWeight: "bold",
                              color: "#222",
                              fontSize: 13,
                            }}
                          >
                            {el}
                          </TableCell>
                        );
                      })}
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          align="center"
                        >
                          <Typography
                            style={{ color: "#1B4965", fontWeight: 'bold' }}
                            fontSize={13}
                          >
                            {coaching.pack}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                        >
                          <Typography
                            style={{ color: "#484848" }}
                            fontSize={13}
                          >
                            {coaching.coaching_code}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                        >
                          <Typography
                            style={{ color: "#484848" }}
                            fontSize={13}
                          >
                            {coaching.coachee_email}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                        >
                          <Typography
                            style={{ color: "#484848" }}
                            fontSize={13}
                          >
                            {coaching.order_date}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                        >
                          <Typography
                            style={{ color: "#484848" }}
                            fontSize={13}
                          >
                            Price in euro HT 
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>

              </div>
            </div>
          </div>
        </div>
      </ContentWrapper> 
        </Wrapper>
    )
}

export default Invoice