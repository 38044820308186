import { Checkbox, FormControlLabel } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { ButtonPrimary } from "../../components/buttonStyles/style";
import { Input, SelectInput } from "../../components/Inputs";
import MultipleSelect from "../../components/MulitpleSelect";
import {
  H5,
  LoadingBigIcon,
  LoadingIcon,
  TextBold,
} from "../../components/textStyles/style";
import { funSubTypes, types } from "../../data/SelectOptions";

let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const Wrapper = styled.div`
  width: 100vw;
  min-height: ${Height};
  display: flex;
  justify-content: center;
`;
const ContentWrapper = styled.div`
  background-color: white;
  width: 90%;
  padding: 30px;
  border-radius: 20px;
  margin: 30px 0;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
`;

const MultiRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
`;
const LoadingWrapper = styled.div`
  width: 100vw;
  min-height: ${Height};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NewWorkshop = () => {
  const history = useHistory();
  const { i18n } = useTranslation();

  const [code, setCode] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [link, setLink] = useState("");
  const [duration, setDuration] = useState("");
  const [poster, setPoster] = useState("");
  const [type, setType] = useState("");
  const [subType, setSubType] = useState("");
  const [studio, setStudio] = useState([]);
  const [author, setAuthor] = useState([]);
  const [voice, setVoice] = useState([]);
  const [keywords, setKeywords] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [free, setFree] = useState(false);
  const [experts, setExperts] = useState([]);
  const [loader, setLoader] = useState(true);

  const submitHandler = async () => {
    setLoading(true);
    setErrorMessage("");
    const TOKEN = localStorage.getItem("TOKEN");
    // simple checker

    var codeChecker = code.split("-");
    if (
      // X123-Y123-X1-Y1-X-Z1
      // Must verify first first that code after split have length of 6 strings
      codeChecker.length !== 6 ||
      codeChecker[0].length !== 4 ||
      codeChecker[1].length !== 4 ||
      codeChecker[2].length !== 2 ||
      codeChecker[3].length !== 2 ||
      codeChecker[4].length !== 1 ||
      codeChecker[5].length !== 2
    ) {
      setLoading(false);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      setErrorMessage("Workshop code invalid");
    } else if (duration <= 0) {
      setLoading(false);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      setErrorMessage("Duration invalid");
    } else {
      try {
        const data = {
          access_token: TOKEN,
          workshop_code: code,
          language: i18n.language,
          link,
          title,
          description,
          poster_link: poster,
          duration: Number(duration),
          type,
          subType,
          author,
          voice,
          studio,
          free,
          keywords,
        };
        const url = process.env.REACT_APP_NEW_WORKSHOP;
        await axios.post(url, data);
        history.goBack();
      } catch (error) {
        setLoading(false);
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        if (error.response.status === 400) {
          setErrorMessage("Invalid data, please fill all fields");
        } else if (error.response.status === 409) {
          setErrorMessage("A workshop exist with provided workshop code !");
        } else {
          setErrorMessage("Connexion problem");
        }
      }
    }
  };

  const getExpertsData = async () => {
    setLoader(true);
    const TOKEN = localStorage.getItem("TOKEN");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TOKEN}`,
      },
    };
    // get experts list
    const ex = await axios.get(
      process.env.REACT_APP_GET_EXPERTS_DROPDOWN,
      config
    );

    setExperts(ex.data);
    setLoader(false);
  };

  useEffect(() => {
    getExpertsData();
  }, []);

  if (loader) {
    return (
      <LoadingWrapper>
        <LoadingBigIcon />
      </LoadingWrapper>
    );
  }

  return (
    <Wrapper>
      <ContentWrapper>
        <H5>New Workshop</H5>
        <Row>
          <TextBold color="#c22222">
            {errorMessage ? errorMessage : " "}
          </TextBold>
        </Row>
        {/** workshop code + title */}
        <Row>
          <Input
            placeholder="Workshop Code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
          <Input
            placeholder="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Row>

        {/** description */}
        <Row>
          <Input
            placeholder="Description"
            multiline={true}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Row>
        {/** type */}
        <Row>
          <SelectInput
            values={types}
            placeholder="Type"
            value={type}
            onChange={(e) => setType(e.target.value)}
          />
        </Row>
        {/** sub type if exist */}
        {type === "fun" && (
          <Row>
            <SelectInput
              values={funSubTypes}
              placeholder="sub-type"
              value={subType}
              onChange={(e) => setSubType(e.target.value)}
            />
          </Row>
        )}
        {/** link + duration */}
        <Row>
          <Input
            placeholder="Video/Audio Link"
            value={link}
            onChange={(e) => setLink(e.target.value)}
          />
          <Input
            type="number"
            placeholder="Duration in minutes"
            value={duration}
            onChange={(e) => setDuration(e.target.value)}
          />
        </Row>
        {/** poster_link */}
        <Row>
          <Input
            placeholder="Poster Link"
            value={poster}
            onChange={(e) => setPoster(e.target.value)}
          />
        </Row>

        {/** author + voice */}
        <MultiRow>
          <MultipleSelect
            placeholder="Author"
            options={experts}
            selectedValues={author}
            setSelectedValues={setAuthor}
          />
          <MultipleSelect
            placeholder="Voice"
            options={experts}
            selectedValues={voice}
            setSelectedValues={setVoice}
          />
        </MultiRow>
        {/** studio  */}
        <Row>
          <MultipleSelect
            placeholder="Studio"
            options={experts}
            selectedValues={studio}
            setSelectedValues={setStudio}
          />
        </Row>
        {/** keywords */}
        <Row>
          <Input
            placeholder="Keywords (sperated with ,) ex: confiance,émotion,stress"
            value={keywords}
            onChange={(e) => setKeywords(e.target.value)}
          />
        </Row>
        {/** freemium access */}
        <FormControlLabel
          style={{ marginRight: 30, marginTop: 10 }}
          control={
            <Checkbox
              checked={free}
              onChange={() => setFree(!free)}
              color="success"
            />
          }
          label="Free Access for Freemium ?"
        />
        {/** button */}
        <Row style={{ marginTop: 40, marginBottom: 40 }}>
          <ButtonPrimary onClick={submitHandler}>
            {loading ? <LoadingIcon color="inherit" /> : "Add new Workshop"}
          </ButtonPrimary>
        </Row>
      </ContentWrapper>
    </Wrapper>
  );
};

export default NewWorkshop;
