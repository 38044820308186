import {
  Button
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { H6, H4 } from "../../components/textStyles/style";
import { RowBetween } from "../app-users/styles";
import { Edit } from "@material-ui/icons";
import { Wrapper } from "./styles";
import styled from "styled-components";
import { Input } from "../../components/Inputs";
import moment from "moment";
import UpdateCoachingPrices from "../../components/modals/UpdateCoachingPrices"
import axios from "axios"


const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: center;
  margin-top: 20px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 20px;
`;

const CompanyPricesCoachings = () => {
  const [coachingPrices, setCoachingPrices] = useState([]);
  const [selectedCoachingPrice, setSelectedCoachingPrice] = useState(null);
  const [showUpdateCoachingPriceModal, setShowUpdateCoachingPriceModal] = useState(false);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    const getCoachingPrices = async () => {
      setLoader(true);
      const TOKEN = localStorage.getItem("TOKEN");
      const config = {
          headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${TOKEN}`,
          },
      };
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_COACHING_PRICES}`,
            config
        );

        setCoachingPrices(response.data.prices)
        setLoader(false);
      } catch(err) {
        console.log('err', err)
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }
    }
    getCoachingPrices()
  }, [coachingPrices._id])

  return (
    <Wrapper>
      <RowBetween>
        <H6 style={{ fontSize: 20 }}>
          4. Coachings Prices
        </H6>
      </RowBetween>
      <RowBetween>
      <Column>
        {coachingPrices.map(price => {
        const updatedAt = moment(price.updatedAt).format("LLL"); 
        return (
          <Row>
            <H4 style={{ fontSize: 20, color: 'black', whiteSpace: 'nowrap' }}>
              {price.label}
            </H4>
            <Input
              value={price.price}
            />
          <H4 style={{ fontSize: 20, color: 'black', whiteSpace: 'nowrap' }}>
              Last update : {updatedAt}
            </H4>
            <Button
              style={{ border: '1px solid' }}
              onClick={() => {
                setSelectedCoachingPrice(null)
                setSelectedCoachingPrice(price)
                setShowUpdateCoachingPriceModal(true);
              }}
            >
              <Edit />
            </Button>
          </Row>
          )})}
      </Column>
      {showUpdateCoachingPriceModal && (
        <UpdateCoachingPrices
          isOpen={showUpdateCoachingPriceModal}
          setIsOpen={setShowUpdateCoachingPriceModal}
          price_id={selectedCoachingPrice._id}
        />
      )}
      </RowBetween>
    </Wrapper>
  );
};

export default CompanyPricesCoachings;
