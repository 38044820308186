import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { useState } from "react";
import { ButtonSecondary, ButtonPrimary, ButtonPrimaryLink } from "../../components/buttonStyles/style";
import NewCompanyCoachingModal from "../../components/modals/NewCompanyCoachingModal";
import { Download } from "@mui/icons-material";
import { H6, H2} from "../../components/textStyles/style";
import { RowBetween } from "../app-users/styles";
import { Wrapper } from "./styles";
import html2pdf from "html2pdf.js";

const CompanyCoachings = ({ coachings, company_id, user }) => {
  const [showCoachingModal, setShowCoachingModal] = useState(null);

  return (
    <Wrapper>
      <RowBetween>
        <H6 style={{ fontSize: 20 }}>
          3. Coachings
          <H6 style={{ fontSize: 20 }} color="#807e7c">
            {" - "} {coachings?.length}{" "}
            {coachings?.length > 1 ? "coachings" : "coaching"}
          </H6>
        </H6>

        <ButtonSecondary onClick={() => setShowCoachingModal(true)}>
          New Coaching
        </ButtonSecondary>
      </RowBetween>
      {coachings?.length > 0 && (
        <TableContainer>
          <Table>
            <TableHead>
              <TableCell
                style={{ fontWeight: "bold", color: "#1B4965", fontSize: 14 }}
              >
                Coaching Code
              </TableCell>
              <TableCell
                style={{ fontWeight: "bold", color: "#1B4965", fontSize: 14 }}
              >
                Order Date
              </TableCell>
              <TableCell
                style={{ fontWeight: "bold", color: "#1B4965", fontSize: 14 }}
              >
                Payment Date
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "bold",
                  color: "#1B4965",
                  fontSize: 14,
                }}
              >
                Coachee Email
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "bold",
                  color: "#1B4965",
                  fontSize: 14,
                }}
              >
                Pack
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "bold",
                  color: "#1B4965",
                  fontSize: 14,
                }}
              >
                Status
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "bold",
                  color: "#1B4965",
                  fontSize: 14,
                }}
              >
                Evaluation
              </TableCell>
              <TableCell
                style={{ fontWeight: "bold", color: "#1B4965", fontSize: 14 }}
              >
                Comment
              </TableCell>
              <TableCell
                style={{ fontWeight: "bold", color: "#1B4965", fontSize: 14 }}
              >
                Created At
              </TableCell>
              <TableCell
                style={{ fontWeight: "bold", color: "#1B4965", fontSize: 14 }}
              >
                Download Invoice
              </TableCell>
            </TableHead>
            <TableBody>
              {coachings?.map((coaching) => {
                const createdAt = moment(coaching.createdAt).format("LLL"); 
                
                return (
                  <TableRow key={coaching._id}>
                    <TableCell style={{ fontSize: 14 }}>
                      {coaching?.coaching_code}
                    </TableCell>
                    <TableCell style={{ fontSize: 14 }}>
                      {coaching?.order_date}
                    </TableCell>
                    <TableCell style={{ fontSize: 14 }}>
                      {coaching?.payment_date}
                    </TableCell>
                    <TableCell style={{ fontSize: 14 }}>
                      {coaching?.coachee_email}
                    </TableCell>
                    <TableCell style={{ fontSize: 14 }}>
                      {coaching?.pack}
                    </TableCell>
                    <TableCell style={{ fontSize: 14 }}>
                      {coaching?.status ? 'payed' : 'not payed'}
                    </TableCell>
                    <TableCell style={{ fontSize: 14 }}>
                      {coaching?.evaluation}
                    </TableCell>
                    <TableCell style={{ fontSize: 14 }}>
                      {coaching?.comment}
                    </TableCell>
                    <TableCell style={{ fontSize: 14 }}>{createdAt}</TableCell>
                    <TableCell style={{ fontSize: 14 }}>
                      <ButtonPrimaryLink
                        to={`/example-invoice/${coaching._id}`}
                      >
                        <Download />
                      </ButtonPrimaryLink>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {/** new company coaching modal */}
      <NewCompanyCoachingModal
        isOpen={showCoachingModal}
        setIsOpen={setShowCoachingModal}
        company={company_id}
        user={user}
      />
    </Wrapper>
  );
};

export default CompanyCoachings;
