import { useContext } from "react";
import { Typography } from "@mui/material";
import { H6 } from "../../components/textStyles/style";
import styled from "styled-components";
import { UserContext } from "../../App";
import { usersRolesOptions } from "../../data/SelectOptions";
import { Wrapper } from "../companies/styles";

const ContentWrapper = styled.div`
  margin-bottom: 20px;
`;
const UserWrapper = styled.div`
  display: flex;
  align-items: center;

  justify-content: space-between;

  padding: 20px;
  border-radius: 15px;
`;
const InfoUserWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const TextWrapper = styled.div``;


const CompanyGeneralInfo = ({ company, updateUser }) => {
  const { user, setUser } = useContext(UserContext);
  if(user) updateUser(user)
  const role = usersRolesOptions.find((r) => r.value === user.role);

  if (!user) {
    return <></>;
  }

  return (
    <Wrapper>
      <ContentWrapper>
        <H6 style={{ fontSize: 20 }}>
          1. General Information
        </H6>
        <UserWrapper>
          <InfoUserWrapper>
            <TextWrapper>
              <Typography variant="h6">
                {company?.name}
              </Typography>
              <Typography fontWeight="500" letterSpacing={1} fontSize={14}>
                Address : {company?.address}
              </Typography>
              <Typography fontWeight="500" letterSpacing={1} fontSize={14}>
                Phone : {company?.phone}
              </Typography>
              <Typography fontWeight="500" letterSpacing={1} fontSize={14}>
                Siret : {company?.siret}
              </Typography>
              <Typography fontWeight="500" letterSpacing={1} fontSize={14}>
                TVA : {company?.tva}
              </Typography>
              <Typography fontWeight="500" letterSpacing={1} fontSize={14}>
                Signup Code : {company?.code}
              </Typography>
            </TextWrapper>
          </InfoUserWrapper>
          <InfoUserWrapper>
            <TextWrapper>
              <Typography fontWeight="500" letterSpacing={1} fontSize={14}>
                Compatatibility service
              </Typography>
            </TextWrapper>
          </InfoUserWrapper>
        </UserWrapper>
      </ContentWrapper>
    </Wrapper>
  );
};

export default CompanyGeneralInfo;
