import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import LazyLoading from "../../components/lazyLoading";
import { H5, H6 } from "../../components/textStyles/style";
import {
  AvatarContainer,
  ContentWrapper,
  LoadingWrapper,
  ProfileSectionContainer,
  ProfileWrapper,
  ReverseRowContainer,
  UserImage,
  WatchedEpisodesContainer,
  Wrapper,
} from "./styles";

import UserAvatar from "@material-ui/icons/Person";
import {
  VerifiedUser,
  IndeterminateCheckBox,
  Email,
  Sms,
} from "@material-ui/icons";
import AccessAlarmsIcon from "@mui/icons-material/AccessAlarms";
import {
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { ChipText } from "../../components/tables/styles";
import moment from "moment";
import { genderOptions, languages } from "../../data/SelectOptions";
import WatchedEpisodes from "../../components/tables/watchedEpisodes";
import SendSmsModal from "../../components/modals/SendSmsModal";
import SendEmailModal from "../../components/modals/SendEmailModal";
import FinishedWorkshops from "./finished-trails-workshops/finishedWorkshops";
import FinshedTrails from "./finished-trails-workshops/finishedTrails";
import UserCommunityRejection from "./UserCommunityRejection";
import MesssagesModal from "../../components/modals/MessagesModal";
import { getSubscription } from "./appUsers";
import { lislColors } from "../../styles/colors";

//REACT_APP_GET_WATCHED_EPISODES=https://api.massatrails.net/dashboard/get-watched-episodes
let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const AppUser = () => {
  const { user_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [banned, setBanned] = useState(false);
  const [showSmsModal, setShowSmsModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [loaderToggleBan, setLoaderToggleBan] = useState(false);
  const [showMessagesHistory, setShowMessagesHistory] = useState(false);
  const [messages, setMessages] = useState([]);
  const [bannedDay, setBannedDay] = useState("");

  useEffect(() => {
    setLoading(true);
    const TOKEN = localStorage.getItem("TOKEN");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TOKEN}`,
      },
    };
    axios
      .get(`${process.env.REACT_APP_GET_APP_USER}?user_id=${user_id}`, config)
      .then(async (res) => {
        setUser(res.data);
        console.log(res.data);
        setBanned(res.data.userData.banned);
        setBannedDay(res.data.userData.bannedAt || "");
        // GET APPOINTMENT MESSAGES
        const messagesResponse = await axios.get(
          `${process.env.REACT_APP_USER_MESSAGES}?email=${res.data.userData.email}`,
          config
        );
        setMessages(messagesResponse.data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [user_id]);

  const toggleBannedUser = () => {
    if (!loaderToggleBan) {
      const TOKEN = localStorage.getItem("TOKEN");
      setLoaderToggleBan(true);
      axios
        .post(process.env.REACT_APP_UPDATE_APP_USER, {
          access_token: TOKEN,
          id: user_id,
          banned: !banned,
          bannedAt: new Date(),
        })
        .then(() => {
          setLoaderToggleBan(false);
          setBanned(!banned);
          setBannedDay(new Date());
        })
        .catch((err) => {
          console.log(err);
          setLoaderToggleBan(false);
        });
    }
  };
  const history = useHistory()

  if (loading) {
    return (
      <LoadingWrapper>
        <LazyLoading height={Height} />
      </LoadingWrapper>
    );
  } else {
    const { userData, userActivity, company, currentTrails } = user;
    const phone = userData.phoneCode + userData.phoneNumber;
    const gender = genderOptions.find(
      (gender) => gender.value === userData.genre
    );
    const language = languages.find((lng) => lng.value === userData.language);
    return (
      <Wrapper>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 2 }}
          open={loaderToggleBan}
          onClick={() => { }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <ContentWrapper>
          <H5>User Informations</H5>
          {/** buttons to send sms / email */}
          <ReverseRowContainer>
            <Button
              style={{
                height: 35,
                textTransform: "capitalize",
                alignItems: "center",
                justifyContent: "center",
                marginRight: 10,
              }}
              variant="contained"
              color="secondary"
              onClick={() => setShowMessagesHistory(true)}
            >
              <AccessAlarmsIcon style={{ fontSize: 18, marginRight: 8 }} />
              Mails history
            </Button>
            <Button
              style={{ height: 35, textTransform: "capitalize" }}
              variant="contained"
              color="info"
              onClick={() => setShowEmailModal(true)}
            >
              <Email style={{ fontSize: 18, marginRight: 8 }} />
              Send email
            </Button>
            {phone.length > 5 && (
              <Button
                style={{
                  height: 35,
                  textTransform: "capitalize",
                  marginLeft: 10,
                }}
                variant="contained"
                onClick={() => setShowSmsModal(true)}
              >
                <Sms style={{ fontSize: 18, marginRight: 8 }} />
                Send an SMS
              </Button>
            )}
          </ReverseRowContainer>
          {/** general info */}
          <ProfileWrapper>
            <ProfileSectionContainer>
              {/** image or avatar */}
              <div style={{ alignSelf: "flex-start", marginRight: 10 }}>
                {userData.image ? (
                  <UserImage
                    src={`${process.env.REACT_APP_BASE_URL}${userData.image}`}
                  />
                ) : (
                  <AvatarContainer>
                    <UserAvatar style={{ fontSize: 70, color: "#A89D9C" }} />
                  </AvatarContainer>
                )}
              </div>
              {/** general data */}
              <div
                style={{
                  width: "100%",
                  marginLeft: 10,
                }}
              >
                {/** gender */}
                <Typography>{`Gender : ${gender.label}`}</Typography>
                {/** name */}
                <Typography>{`Fullname : ${userData.firstname} ${userData.lastname}`}</Typography>

                {/** email */}
                <Typography variant="subtitle1">
                  {`mail : ${userData.email}`}
                  {userData.status === "active" ? (
                    <VerifiedUser
                      style={{ fontSize: 17, marginLeft: 3, color: "#A0C460" }}
                    />
                  ) : (
                    <IndeterminateCheckBox
                      style={{ fontSize: 17, marginLeft: 3, color: "#c22222" }}
                    />
                  )}
                </Typography>
                {/** phone */}
                {phone.length > 5 && (
                  <Typography variant="subtitle1">
                    {`Phone : ${phone}`}
                    {userData.verify_number ? (
                      <VerifiedUser
                        style={{
                          fontSize: 17,
                          marginLeft: 3,
                          color: "#A0C460",
                        }}
                      />
                    ) : (
                      <IndeterminateCheckBox
                        style={{
                          fontSize: 17,
                          marginLeft: 3,
                          color: "#c22222",
                        }}
                      />
                    )}
                  </Typography>
                )}
                {/** created at and last connextion */}
                <Typography variant="='subtitle1">
                  {`Account created : ${moment(userData.createdAt).format(
                    "DD/MM/YYYY HH:mm"
                  )} `}
                </Typography>

                <Typography variant="subtitle1">
                  {`Last login : ${moment(userData.last_connexion).format(
                    "DD/MM/YYYY HH:mm"
                  )}\n`}
                </Typography>
                {/** language */}
                <Typography variant="='subtitle1">
                  {`Language : ${language.label}`}
                </Typography>
                {/** abonnement */}
                <div style={{ display: 'flex', alignItems: 'center' }}>

                  <ChipText style={{ width: "200px", marginRight: 15, marginTop: 15 }}>
                    {getSubscription(userData.subscription_info)}
                  </ChipText>

                  {userData.subscription_info === "freemium" && <Button
                    style={{ height: 30, marginTop: 15 }}
                    variant="outlined"
                    onClick={() => {
                      if (window.confirm("Are you sure you want to upgrade to lifetime subscription?")) {
                        axios.post(`${process.env.REACT_APP_DASHBOARD_USERS}/upgrade-to-lifetime`, {
                          user: userData._id
                        }).then(() => {
                          history.go(0)
                        })
                      }
                    }
                    }
                  >
                    Change to Lifetime
                  </Button>
                  }
                  {userData.lifetime_payment === "" && <Button
                    style={{ height: 30, marginTop: 15 }}
                    variant="outlined"
                    onClick={() => {
                      if (window.confirm("Are you sure you want to downgrade to freemium subscription?")) {
                        axios.post(`${process.env.REACT_APP_DASHBOARD_USERS}/downgrade-to-freemium`, {
                          user: userData._id
                        }).then(() => {
                          history.go(0)
                        })
                      }
                    }
                    }
                  >
                    Change to Freemium
                  </Button>
                  }
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: 20,
                  }}
                >
                  {/** rank  */}
                  <Typography variant="='subtitle1">
                    {`Rank : ${userData.total_points < 1000
                      ? "Randonneur"
                      : userData.total_points < 2000 &&
                        userData.total_points >= 1000
                        ? "Explorateur"
                        : "Sherpa"
                      }`}
                  </Typography>

                  {/** points */}
                  <Typography style={{ marginTop: 5 }} variant="='subtitle1">
                    {`Nb. tokens : ${userData.current_points} tokens / ${userData.total_points} total earned tokens`}
                  </Typography>
                  {/** Nombre des trails */}
                  <Typography style={{ marginTop: 5 }} variant="='subtitle1">
                    {`Nb. trails : ${userActivity.trails.length} Used trail access / ${userData.number_trails} Total trails access `}
                  </Typography>

                  {/** address */}
                  <Typography style={{ marginTop: 5 }} variant="='subtitle1">
                    {`Address : ${userData.address || " - "}`}
                  </Typography>
                  {/** city */}
                  <Typography style={{ marginTop: 5 }} variant="='subtitle1">
                    {`City : ${userData.city || " - "}`}
                  </Typography>
                  {/** country */}
                  <Typography style={{ marginTop: 5 }} variant="='subtitle1">
                    {`Country : ${userData.country || " - "}  `}
                  </Typography>
                </div>

                {/** buttons stripe and revenue cat */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    marginTop: 5,
                  }}
                >
                  <Button
                    style={{ height: 30 }}
                    variant="contained"
                    onClick={(e) =>
                      window.open(
                        `https://dashboard.stripe.com/customers/${userData.customer}`,
                        "_blank"
                      )
                    }
                  >
                    Stripe account
                  </Button>
                </div>
              </div>
            </ProfileSectionContainer>
            {/** second section */}
            <ProfileSectionContainer>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignSelf: "flex-start",
                  width: "100%",
                }}
              >
                {/** community rejections */}
                <H6
                  style={{
                    fontSize: 18,
                    textAlign: "center",
                  }}
                >
                  {`Community Rejection `}
                </H6>
                <UserCommunityRejection user_id={user_id} />
                {/** access to community */}
                <FormControlLabel
                  style={{ marginRight: 80, marginTop: 10 }}
                  control={
                    <Checkbox
                      checked={!banned}
                      onChange={toggleBannedUser}
                      color="success"
                    />
                  }
                  label={
                    banned ? (
                      <Typography
                        fontWeight="bold"
                        color="#c22222"
                        variant="subtitle1"
                      >
                        No access to community : (Banned at{" "}
                        {moment(bannedDay).format("DD/MM/YYYY HH:mm")})
                      </Typography>
                    ) : (
                      <Typography
                        fontWeight="bold"
                        color="#3c7d42"
                        variant="subtitle1"
                      >
                        Allowed to access community
                      </Typography>
                    )
                  }
                />
              </div>
            </ProfileSectionContainer>
          </ProfileWrapper>

          {/** finished and current trails */}
          <WatchedEpisodesContainer>
            <FinshedTrails currentTrails={currentTrails} user={user_id} />
          </WatchedEpisodesContainer>

          {/* watched episodes table*/}
          <WatchedEpisodesContainer>
            <WatchedEpisodes user={user_id} />
          </WatchedEpisodesContainer>

          {/** finished workshops */}
          <WatchedEpisodesContainer>
            <FinishedWorkshops user={user_id} />
          </WatchedEpisodesContainer>
        </ContentWrapper>
        {/** modal to send sms */}
        <SendSmsModal
          phone={phone}
          isOpen={showSmsModal}
          setIsOpen={setShowSmsModal}
        />
        {/** modal to send email */}
        <SendEmailModal
          email={userData.email}
          isOpen={showEmailModal}
          setIsOpen={setShowEmailModal}
        />
        {/** modal to show history messages */}
        <MesssagesModal
          isOpen={showMessagesHistory}
          setIsOpen={setShowMessagesHistory}
          messages={messages}
        />
      </Wrapper>
    );
  }
};

export default AppUser;
