import { useContext, useState } from "react";
import { AccountCircle } from "@material-ui/icons";
import { Typography } from "@mui/material";
import {
  ButtonPrimary,
  ButtonSecondary,
} from "../../components/buttonStyles/style";
import { H5 } from "../../components/textStyles/style";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import SeeYouImg from "../../images/main/see_you_popup.svg";
import ChangeUserPassword from "../../components/modals/ChangeUserPassword";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../App";
import { usersRolesOptions } from "../../data/SelectOptions";

const ContentWrapper = styled.div`
  margin-bottom: 20px;
`;
const UserWrapper = styled.div`
  display: flex;
  align-items: center;

  justify-content: space-between;

  padding: 20px;
  border-radius: 15px;
`;
const InfoUserWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const TextWrapper = styled.div``;

const ButtonsContainer = styled.div`
  width: 540px;
  display: flex;
  gap: 5px;
  margin-right: 5px;
`;

const CompanyAccount = ({ company, updateUser }) => {
  const history = useHistory();
  const [showChangePassword, setShowChangePassword] = useState(false);
  const { user, setUser } = useContext(UserContext);
  if(user) updateUser(user)
  const role = usersRolesOptions.find((r) => r.value === user.role);
  const MySwal = withReactContent(Swal);

  const logoutHandler = () => {
    MySwal.fire({
      title: <H5>Merci de votre visite</H5>,
      html: <></>,
      imageUrl: SeeYouImg,
      imageWidth: 200,
      imageHeight: 150,
      imageAlt: "Custom image",
      timer: 3000,
      showConfirmButton: false,

      timerProgressBar: true,
      padding: "2vw",
      showCloseButton: true,
      showClass: {
        popup: "animate__animated animate__fadeInDown animate__fast",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp animate__faster",
      },
    }).then(() => {
      localStorage.removeItem("TOKEN");
      setUser({});
      history.replace("/signin");
    });
  };

  if (!user) {
    return <></>;
  }

  return (
    <ContentWrapper>
      <UserWrapper>
        <InfoUserWrapper>
          <AccountCircle
            style={{ fontSize: 80, color: "#1b4965", marginRight: 5 }}
          />
          <TextWrapper>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography
                style={{ marginRight: 10 }}
                color="#444 "
                variant="h7"
              >
                LISL UP Dashboard
              </Typography>
            </div>
            <Typography variant="h6">
              {company?.name}
            </Typography>
            <Typography fontWeight="500" letterSpacing={1} fontSize={14}>
              {company?.address}
            </Typography>
            <Typography color="#1b4965" variant="subtitle2">
              {" "}
              {role ? role.label : ""}
            </Typography>
          </TextWrapper>
        </InfoUserWrapper>
        <InfoUserWrapper>
          <ButtonsContainer>
            <ButtonPrimary width="100%" onClick={setShowChangePassword}>
              Change Password
            </ButtonPrimary>
            <ButtonSecondary
              style={{
                background: "#c2222290",
                borderColor: "transparent",
                color: "#ffffff",
              }}
              width="100%"
              onClick={logoutHandler}
            >
              Logout
            </ButtonSecondary>
          </ButtonsContainer>
        </InfoUserWrapper>
      </UserWrapper>
      {/** modal to change password */}
      <ChangeUserPassword
        isOpen={showChangePassword}
        setIsOpen={setShowChangePassword}
      />
    </ContentWrapper>
  );
};

export default CompanyAccount;
