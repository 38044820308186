import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import {
  ButtonPrimary,
  ButtonPrimaryLink,
} from "../../components/buttonStyles/style";
import { Input, SelectInput } from "../../components/Inputs";
import {
  H5,
  H6,
  H7,
  LoadingBigIcon,
  LoadingIcon,
  TextBold,
} from "../../components/textStyles/style";
import {
  companiesPaymentOptions,
  companyTypologieOptions,
  countries,
  languages,
  sectorsOptions,
} from "../../data/SelectOptions";
import "../../styles/custom-phone-input.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import SuccessImage from "../../images/main/green_circle_tick.svg";
import { ChipText } from "../../components/tables/styles";
import { Button, Checkbox, Divider, FormControlLabel, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import CompanyContacts from "./companyContacts";
import CompanyFiles from "./CompanyFiles";
import MultipleSelect from "../../components/MulitpleSelect";
import AddSectionQuestion from "../../components/modals/AddSectionQuestion";
import { Delete, Edit, Update } from "@material-ui/icons";
import UpdateSectionQuestion from "../../components/modals/UpdateSectionQuestion";
import { lislColors } from "../../styles/colors";
import { Download } from "@mui/icons-material";
import CompanyAnalysis from "../../components/modals/CompanyAnalysis";
import AddCompagne from "../../components/modals/AddCompagne";
import UpdateCompagne from "../../components/modals/UpdateCompagne";
import AddReportCompaign from "../../components/modals/AddReportCompaign";

let Height = "calc(100vh - clamp(54px, 8vw, 84px) - 62px)";

const Wrapper = styled.div`
  width: 100vw;
  min-height: ${Height};
  display: flex;
  justify-content: center;
`;
const ContentWrapper = styled.div`
  background-color: white;
  width: 90%;
  padding: 30px;
  border-radius: 20px;
  margin: 30px 0;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
`;

const StatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`;

const LoadWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  align-items: center;
  justify-content: center;
`;

const PhoneInputWrapper = styled.div`
  border: none;
  overflow: hidden;
  width: 100%;
  height: 56px;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const SectionWrapper = styled.div`
  background-color: #fbf6f3;
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 20px;
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 5px;
`;

const UpdateCompany = () => {
  const { company_id } = useParams();
  const MySwal = withReactContent(Swal);
  const [loader, setLoader] = useState(true);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [name, setName] = useState("");
  const [siret, setSiret] = useState("");
  const [typologie, setTypologie] = useState("");
  const [phone, setPhone] = useState("");
  const [tva, setTva] = useState("");
  const [sector, setSector] = useState("");
  const [totalEmployees, setTotalEmployees] = useState("");
  const [domain, setDomain] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("FR");
  const [code, setCode] = useState("");
  const [payment, setPayment] = useState("");
  const [mode, setMode] = useState("");
  const [maxUsersTest, setMaxUsersTest] = useState("");
  const [testLoading, setTestLoading] = useState(false);
  const [startDateTest, setStartDateTest] = useState("");
  const [endDateTest, setEndDateTest] = useState("");
  const [numOrder, setNumOrder] = useState("");
  const [numberTrailsTest, setNumberTrailsTest] = useState("");
  const [status, setStatus] = useState("");
  const [showName, setShowName] = useState("");
  const [statusLoader, setStatusLoader] = useState(false);
  const [groups, setGroups] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [reporting, setReporting] = useState([])
  const [reportingLoader, setReportingLoader] = useState(false)
  const [files, setFiles] = useState([]);
  const [group, setGroup] = useState("");
  const [trails, setTrails] = useState([])
  const [showAddCompagne, setShowAddCompagne] = useState(false)
  const [showUpdateCompagne, setShowUpdateCompagne] = useState(false)
  const [compagneToUpdate, setCompagneTopUpdate] = useState(null);
  const [compagnes, setCompagnes] = useState([])
  const [nego, setNego] = useState(false)
  const [selectedCompagne, setSelectedCompagne] = useState(null);
  const [showUploadModal, setShowUploadModal] = useState(false)
  const [contracts, setContracts] = useState([]);
  //const [totalUsers, setTotalUsers] = useState(0)
  //const [profilesNumber, setProfilesNumber] = useState(0)
  //const [finishedProfilesNumber, setFinishedProfilesNumber] = useState(0)
  //const [section1, setSection1] = useState('')
  //const [section2, setSection2] = useState('')
  // const [section3, setSection3] = useState('')
  //const [showCompanyAnalysis, setShowComanyAnalysis] = useState(false)
  const TOKEN = localStorage.getItem("TOKEN");

  const activateTestHandler = async () => {
    if (!testLoading) {
      setTestLoading(true);
      setErrorMessage("");
      const TOKEN = localStorage.getItem("TOKEN");
      try {
        const data = {
          access_token: TOKEN,
          id: company_id,
          start_date: startDateTest,
          end_date: endDateTest,
          start_date_test: startDateTest,
          end_date_test: endDateTest,
          max_users_test: Number(maxUsersTest),
          max_users: Number(maxUsersTest),
          number_trails_test: Number(numberTrailsTest),
          number_trails: Number(numberTrailsTest),
          mode: "test",
        };

        await axios.put(process.env.REACT_APP_UPDATE_COMPANY, data);

        setTimeout(() => {
          setShowName(name);
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          setTestLoading(false);
          MySwal.fire({
            title: <H5>Test Period has been activated</H5>,
            html: <></>,
            imageUrl: SuccessImage,
            imageWidth: 200,
            imageHeight: 150,
            imageAlt: "Custom image",
            timer: 1500,
            showConfirmButton: false,

            timerProgressBar: true,
            padding: "2vw",
            showCloseButton: true,
            showClass: {
              popup: "animate__animated animate__fadeInDown animate__fast",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp animate__faster",
            },
          });
        }, 1000);
      } catch (error) {
        setTestLoading(false);
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        console.log(error);
        setErrorMessage("Probléme de Connexion");
      }
    }
  };

  const saveReporting = async () => {
    if (!reportingLoader) {
      setReportingLoader(true);
      setErrorMessage("");
      const TOKEN = localStorage.getItem("TOKEN");
      try {
        const data = {
          access_token: TOKEN,
          reporting,
          id: company_id,
        };

        await axios.put(process.env.REACT_APP_UPDATE_COMPANY, data);

        setTimeout(() => {


          setReportingLoader(false);
          MySwal.fire({
            title: <H5>Modifications Enregisté</H5>,
            html: <></>,
            imageUrl: SuccessImage,
            imageWidth: 200,
            imageHeight: 150,
            imageAlt: "Custom image",
            timer: 1500,
            showConfirmButton: false,

            timerProgressBar: true,
            padding: "2vw",
            showCloseButton: true,
            showClass: {
              popup: "animate__animated animate__fadeInDown animate__fast",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp animate__faster",
            },
          });
        }, 1000);
      } catch (error) {
        setReportingLoader(false);
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        console.log(error);
        setErrorMessage("Probléme de Connexion");
      }
    }
  }

  const submitHandler = async () => {
    if (!loading) {
      setLoading(true);
      setErrorMessage("");
      const TOKEN = localStorage.getItem("TOKEN");
      try {
        const data = {
          access_token: TOKEN,
          name,
          typologie,
          sector,
          siret,
          phone,
          group,
          total_employees: Number(totalEmployees),
          domain,
          address,
          country,
          code,
          num_order: numOrder,
          tva,
          id: company_id,
          payment,
        };

        await axios.put(process.env.REACT_APP_UPDATE_COMPANY, data);

        setTimeout(() => {
          setShowName(name);
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          setLoading(false);
          MySwal.fire({
            title: <H5>Modifications Enregisté</H5>,
            html: <></>,
            imageUrl: SuccessImage,
            imageWidth: 200,
            imageHeight: 150,
            imageAlt: "Custom image",
            timer: 1500,
            showConfirmButton: false,

            timerProgressBar: true,
            padding: "2vw",
            showCloseButton: true,
            showClass: {
              popup: "animate__animated animate__fadeInDown animate__fast",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp animate__faster",
            },
          });
        }, 1000);
      } catch (error) {
        setLoading(false);
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        console.log(error);
        setErrorMessage("Probléme de Connexion");
      }
    }
  };

  const toggleStatus = async () => {
    if (!statusLoader) {
      setStatusLoader(true);
      setErrorMessage(null);
      const TOKEN = localStorage.getItem("TOKEN");
      try {
        const value = status === "active" ? "inactive" : "active";
        const data = {
          access_token: TOKEN,
          status: value,
          id: company_id,
        };

        await axios.put(process.env.REACT_APP_UPDATE_COMPANY, data);

        setTimeout(() => {
          setStatusLoader(false);
          setStatus(value);
        }, 1000);
      } catch (error) {
        setStatusLoader(false);
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        console.log(error);
        setErrorMessage("Probléme de Connexion");
      }
    }
  };

  const toggleNego = async () => {
    if (!statusLoader) {
      setNego(!nego)
      const TOKEN = localStorage.getItem("TOKEN");
      try {

        const data = {
          access_token: TOKEN,
          nego: !nego,
          id: company_id,
        };

        await axios.put(process.env.REACT_APP_UPDATE_COMPANY, data);


      } catch (error) {

        console.log(error);

      }
    }
  };

  const getCompanyData = async () => {
    setLoader(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TOKEN}`,
      },
    };
    const response = await axios.get(
      `${process.env.REACT_APP_GET_COMPANY}?company_id=${company_id}`,
      config
    );
    const company = response.data;

    // SET INPUTS TO EXPERT VALUES
    setNumOrder(company.num_order);
    setFiles(company.files ? company.files : []);
    setName(company.name);
    setShowName(company.name);
    setTypologie(company.typologie);
    setSector(company.sector);
    setSiret(company.siret);
    setPhone(company.phone);
    setMode(company.mode ? company.mode : "");
    setReporting(company.reporting ? company.reporting : [])
    setDomain(company.domain);
    setGroup(company.group ? company.group : "");
    setStatus(company.status);
    setTotalEmployees(company.total_employees);
    setCode(company.code);
    // setSection1(company.section1 ? company.section1 : "")
    // setSection2(company.section2 ? company.section2 : "")
    // setSection3(company.section3 ? company.section3 : "")
    setNego(company.nego ? company.nego : false)
    setMaxUsersTest(company.max_users_test ? company.max_users_test : "");
    setNumberTrailsTest(
      company.number_trails_test ? company.number_trails_test : ""
    );
    setContacts(company.contacts);

    setPayment(company.payment);
    setTva(company.tva);
    setAddress(company.address ? company.address : "");
    setCountry(company.country ? company.country : "");
    const start = company.start_date_test
      ? moment(company.start_date_test).format("yyyy-MM-DD")
      : "";
    const end = company.end_date_test
      ? moment(company.end_date_test).format("yyyy-MM-DD")
      : "";
    setStartDateTest(start);
    setEndDateTest(end);

    // get groups list
    const grps = await axios.get(
      `${process.env.REACT_APP_GROUPS}?dropdown=true`,
      config
    );

    // profiles counts
    /* const profilesData = await axios.get(`${process.env.REACT_APP_QESTIONNAIRES}/company-stats?company=${company_id}`)

     setTotalUsers(profilesData.data.total)
     setProfilesNumber(profilesData.data.profiles)
     setFinishedProfilesNumber(profilesData.data.finished)*/

    // GET COMPANY COMPAGNES
    const comps = await axios.get(`${process.env.REACT_APP_QESTIONNAIRES}/get-compagnes?company=${company_id}`)
    setCompagnes(comps.data)
    setGroups(grps.data);
    if (company.group) {
      const currentGroup = grps.data.find((el) => el.value === company.group);

      setGroup(currentGroup ? currentGroup.value : "");
    }
    const trailsDropdown = await axios.get(
      `${process.env.REACT_APP_GET_TRAILS_DROPDOWN}`,
      config
    );
    setTrails(trailsDropdown.data)
    setLoader(false);
  };


  useEffect(() => {

    getCompanyData();
  }, [company_id, TOKEN]);



  const activeContracts = contracts.filter(contract => {
    const currentDate = new Date();
    const startDate = new Date(contract.startDate);
    const endDate = new Date(contract.endDate);
    console.log('startDate', startDate)
    console.log('currentDate', currentDate)
    console.log("endDate", endDate)

    return currentDate.getTime() >= startDate.getTime() && currentDate.getTime() <= endDate.getTime();
  });


  return (
    <Wrapper>
      <ContentWrapper>
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <H5>Company - {showName}</H5>
          <ButtonPrimaryLink to={`/company-stats/${company_id}`}>
            Consult Statistics
          </ButtonPrimaryLink>
        </div>
        <Divider style={{ marginTop: 10, marginBottom: 20 }} />
        {loader ? (
          <LoadWrapper>
            <LoadingBigIcon />
          </LoadWrapper>
        ) : (
          <>
            <div>
              <StatusContainer>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <H7>Status : </H7>
                  {status === "active" ? (
                    <ChipText success>Active</ChipText>
                  ) : (
                    <ChipText danger>Inactive</ChipText>
                  )}
                </div>

                {status === "active" ? (
                  <ButtonPrimary onClick={toggleStatus}>
                    {statusLoader ? <LoadingIcon color="inherit" /> : "Disable"}
                  </ButtonPrimary>
                ) : (
                  <ButtonPrimary onClick={toggleStatus}>
                    {statusLoader ? <LoadingIcon color="inherit" /> : "Activer"}
                  </ButtonPrimary>
                )}
              </StatusContainer>
              <div>
                <FormControlLabel

                  control={
                    <Checkbox
                      checked={nego}
                      onChange={toggleNego}
                      color="success"
                    />
                  }
                  label={nego ? 'Negotiation in progress with Company' : "Check to make as \"Under Negotiation\""}
                />
              </div>
            </div>
            <SectionWrapper>
              <Row>
                <TextBold color="#c22222">
                  {errorMessage ? errorMessage : " "}
                </TextBold>
              </Row>

              <H6 style={{ fontSize: 20 }}>1. Identification</H6>
              {/** number of order */}
              <Row>
                <Input
                  style={{ marginTop: 20 }}
                  placeholder="Order number *"
                  value={numOrder}
                  onChange={(e) => setNumOrder(e.target.value)}
                />
              </Row>
              {/** group name  - typologie */}
              <Row>
                <SelectInput
                  values={companyTypologieOptions}
                  placeholder="Typology *"
                  value={typologie}
                  onChange={(e) => setTypologie(e.target.value)}
                />

                <SelectInput
                  values={groups}
                  placeholder="Group (optional)"
                  value={group}
                  onChange={(e) => setGroup(e.target.value)}
                />
              </Row>
              {/** company name */}
              <Row>
                <Input
                  placeholder="Company name *"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <SelectInput
                  values={companiesPaymentOptions}
                  placeholder="Payment Method *"
                  value={payment}
                  onChange={(e) => setPayment(e.target.value)}
                />
              </Row>

              {/** phone -sector */}
              <Row>
                <SelectInput
                  values={sectorsOptions}
                  placeholder="Activity Sector *"
                  value={sector}
                  onChange={(e) => setSector(e.target.value)}
                />
                <PhoneInputWrapper>
                  <PhoneInput
                    placeholder="Phone Number (optional)"
                    value={phone}
                    onChange={setPhone}
                    defaultCountry="FR"
                    international={false}
                    rules={{ required: true }}
                  />
                </PhoneInputWrapper>
              </Row>
              {/** siret + tva*/}
              <Row>
                <Input
                  placeholder="TVA Number (optional)"
                  value={tva}
                  onChange={(e) => setTva(e.target.value)}
                />
                <Input
                  placeholder="SIRET (optional)"
                  value={siret}
                  onChange={(e) => setSiret(e.target.value)}
                />
              </Row>
              <Row style={{ marginBottom: 20 }}>
                <Input
                  placeholder="Company domain (ex : lislup.com) (optional)"
                  value={domain}
                  onChange={(e) => setDomain(e.target.value)}
                />
                <Input
                  type="number"
                  placeholder="Total Employees *"
                  value={totalEmployees}
                  onChange={(e) => setTotalEmployees(e.target.value)}
                />
              </Row>
              {/** address  - country */}
              <Row>
                <Input
                  placeholder="Billing Address *"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
                <SelectInput
                  values={countries}
                  placeholder="country *"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                />
                <Input
                  placeholder="Signup code *"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                />
              </Row>
              <Row
                style={{
                  justifyContent: "flex-end",
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <ButtonPrimary onClick={submitHandler}>
                  {loading ? (
                    <LoadingIcon color="inherit" />
                  ) : (
                    "Save Identification data"
                  )}
                </ButtonPrimary>
              </Row>
            </SectionWrapper>
            <SectionWrapper>
              {!loader && (
                <CompanyContacts contacts={contacts} company_id={company_id} />
              )}
            </SectionWrapper>
            <SectionWrapper>
              <br></br>
              <H6 style={{ fontSize: 20 }}>3. TEST</H6>
              <Row>
                <Input
                  disabled={activeContracts.length > 0}
                  type="number"
                  placeholder="Test - Max users *"
                  value={maxUsersTest}
                  onChange={(e) => setMaxUsersTest(e.target.value)}
                />
                <Input
                  disabled={activeContracts.length > 0}
                  type="number"
                  placeholder="Test - Trails access number *"
                  value={numberTrailsTest}
                  onChange={(e) => setNumberTrailsTest(e.target.value)}
                />
              </Row>

              <Row>
                <div style={{ width: "100%" }}>
                  <H7 style={{ marginBottom: 8 }}>Test Start Date *</H7>
                  <Input
                    disabled={activeContracts.length > 0}
                    type="date"
                    value={startDateTest}
                    onChange={(e) => setStartDateTest(e.target.value)}
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <H7 style={{ marginBottom: 8 }}>Test - End Date *</H7>
                  <Input
                    disabled={activeContracts.length > 0}
                    type="date"
                    value={endDateTest}
                    onChange={(e) => setEndDateTest(e.target.value)}
                  />
                </div>
              </Row>
              {activeContracts.length === 0 && <Row
                style={{
                  justifyContent: "flex-end",
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <ButtonPrimary onClick={activateTestHandler}>
                  {testLoading ? (
                    <LoadingIcon color="inherit" />
                  ) : (
                    "Save Test Data"
                  )}
                </ButtonPrimary>
              </Row>}
            </SectionWrapper>
            <SectionWrapper>
              <CompanyFiles contracts={contracts} setContracts={setContracts} mode={mode} files={files} company_id={company_id} />
            </SectionWrapper>
            <SectionWrapper style={{ paddingTop: 20 }}>
              <H6 style={{ fontSize: 20 }}>
                5. Reporting
              </H6>
              <div style={{ marginTop: 20, display: 'flex', alignItems: 'center' }}>
                <MultipleSelect
                  transparent
                  placeholder="Choose among list. (to show in company report)"
                  options={trails}
                  selectedValues={reporting}
                  setSelectedValues={setReporting}
                />
                <ButtonPrimary style={{ marginLeft: 20 }} onClick={saveReporting}>
                  {reportingLoader ? <LoadingIcon color="inherit" /> : "Save"}
                </ButtonPrimary>
              </div>
            </SectionWrapper>
            <Divider style={{ marginTop: 20, marginBottom: 20 }} />
            <SectionWrapper >
              <div
                style={{
                  marginTop: 20,

                }}
              >
                <div>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <H6 style={{ fontSize: 20 }}>
                      6. Company Campaigns
                    </H6>
                    <div style={{ width: 220 }}>
                      <ButtonPrimary width={220}
                        onClick={() => setShowAddCompagne(true)}
                      >
                        Add new Campaign
                      </ButtonPrimary>
                    </div>
                  </div>
                  <div style={{ backgroundColor: '#ffffff50', padding: 10, marginTop: 20 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">
                            <Typography style={{ fontSize: 14, color: '#1b4965', fontWeight: 600 }} >
                              Campaign name
                            </Typography>

                          </TableCell>
                          <TableCell align="center">
                            <Typography style={{ fontSize: 14, color: '#1b4965', fontWeight: 600 }} >
                              Start Date
                            </Typography>

                          </TableCell>
                          <TableCell align="center">
                            <Typography style={{ fontSize: 14, color: '#1b4965', fontWeight: 600 }} >
                              End Date
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography style={{ fontSize: 14, color: '#1b4965', fontWeight: 600 }} >
                              Reports
                            </Typography>
                          </TableCell>
                          <TableCell style={{ fontSize: 14, color: '#1b4965', fontWeight: 600 }} align="center">
                            Download Excel
                          </TableCell>
                          <TableCell style={{ fontSize: 14, color: '#1b4965', fontWeight: 600 }} align="center">
                            Change Dates
                          </TableCell>
                          <TableCell style={{ fontSize: 14, color: '#1b4965', fontWeight: 600 }} align="center">
                            Details
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {compagnes.map((com, i) => {

                          return (
                            <TableRow key={com._id}>
                              <TableCell align="center">
                                <Typography style={{ fontSize: 14, color: '#484848' }} >
                                  {com.name}
                                </Typography>

                              </TableCell>
                              <TableCell align="center">
                                <Typography style={{ fontSize: 14, color: '#484848' }} >

                                  {moment(com.startDate).format("yyyy-MM-DD")}
                                </Typography>
                              </TableCell>
                              <TableCell align="center">
                                <Typography style={{ fontSize: 14, color: '#484848' }} >
                                  {moment(com.endDate).format("yyyy-MM-DD")}
                                </Typography>
                              </TableCell>
                              <TableCell align="center">
                                {com.files && com.files.length > 0 ? com.files.map(el =>
                                  <div key={el._id} style={{ marginTop: 8, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <a href={el.url} target='_blank'
                                      rel='noopener noreferrer' style={{ fontSize: 14, color: '#484848', }} >
                                      {el.name}
                                    </a>
                                    <Button
                                      size="small"
                                      onClick={() => {
                                        if (window.confirm("Are you sure you want to delete this document?")) {
                                          axios.delete(`${process.env.REACT_APP_QESTIONNAIRES}/delete-compaign-report?file=${el._id}`).then(() => {
                                            getCompanyData()
                                          })
                                        }
                                      }}
                                    >
                                      <Delete />
                                    </Button>
                                  </div>
                                ) : <>No Reports uploaded
                                </>}
                                <Typography style={{ fontSize: 14, color: '#0000FF', cursor: 'pointer', textDecorationLine: 'underline', marginTop: 10 }} onClick={() => {
                                  setSelectedCompagne(com)
                                  setShowUploadModal(true)
                                }} >
                                  Upload
                                </Typography>
                              </TableCell>

                              <TableCell align="center">
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                  <ButtonPrimary
                                    onClick={() => window.open(`${process.env.REACT_APP_QESTIONNAIRES}/company-bilan?company=${company_id}&compagne=${com._id}`, '_blank')}
                                  >
                                    <Download />
                                  </ButtonPrimary>

                                </div>
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  onClick={() => {
                                    setCompagneTopUpdate(com)
                                    setShowUpdateCompagne(true)
                                  }}
                                >
                                  <Edit />
                                </Button>
                              </TableCell>
                              <TableCell>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                  <ButtonPrimaryLink
                                    to={`/compaign/${com._id}`}
                                    width="54px"
                                    height="54px"
                                  >
                                    <span style={{ fontSize: 24 }}>&#8594;</span>
                                  </ButtonPrimaryLink>
                                </div>
                              </TableCell>
                            </TableRow>
                          )
                        })}

                      </TableBody>
                    </Table>

                  </div>

                </div>

              </div>

            </SectionWrapper>
          </>
        )}
      </ContentWrapper>
      {/** modal add compagne */}
      <AddCompagne isOpen={showAddCompagne}
        company={company_id}
        compagnes={compagnes}
        getCompanyData={getCompanyData}
        setIsOpen={setShowAddCompagne} />


      {/** modal to update compagne */}
      {
        compagneToUpdate && <UpdateCompagne
          isOpen={showUpdateCompagne}

          setIsOpen={setShowUpdateCompagne}
          getCompanyData={getCompanyData}
          compagneToUpdate={compagneToUpdate}
          setCompagneTopUpdate={setCompagneTopUpdate}
        />
      }
      {/** modal to upload report to compaign */}
      <AddReportCompaign
        isOpen={showUploadModal}
        setIsOpen={setShowUploadModal}
        selectedCompagne={selectedCompagne}
        setSelectedCompagne={setSelectedCompagne}
        getCompanyData={getCompanyData}
      />
      {/** company analysis */}
      {/*  <CompanyAnalysis
        isOpen={showCompanyAnalysis}
        setIsOpen={setShowComanyAnalysis}
        company={company_id}
        section1={section1} section2={section2} section3={section3} setSection1={setSection1} setSection2={setSection2} setSection3={setSection3}
      />*/}
    </Wrapper >
  );
};

export default UpdateCompany;
